/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import useHoney from "../utlis/useHoney"
import styled, { css } from "styled-components"
import formatMoney from "src/utlis/formatMoney"
import InputNumber from "../components/Elemnts/InputNumber"
import Modal from "../components/Modal/Modal"
import orderContext from "../components/OrderContext"

const StyledWrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-family: ${({theme}) => theme.font.family.Montserrat};


  ${({theme}) => theme.media.desktop} {
    margin-top: 40px;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 330px);
    justify-content: center;
    align-items: center;

  }
`;
const Product = styled.div`
  width: 90%;
  max-width: 1400px;
  height: auto;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.desktop} {
    width: 80%;
    min-height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;
const StyledImage = styled.img`
  width: auto;
  height: 280px;
  margin: 0 auto;

  ${({theme}) => theme.media.desktop} {
    width: auto;
    max-width: 380px;
    height: auto;
    max-height: 380px;
    padding: 50px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2%;
    margin-right: 6rem;
  }
`
const StyledRightSite = styled.div`
  width: auto;
  display: grid;

  place-items: center;

  ${({theme}) => theme.media.desktop} {
    margin-left: 6rem;
    place-items: start;
  }
`

const StyledP = styled.p`
  font-family: ${({theme}) => theme.font.family.Montserrat};
  font-size: ${({theme}) => theme.font.size.s};
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 1.6;
  padding: 0 10px;

  span {
    font-size: 16px;
  }

  ${({price}) => price && css`
    text-align: center;

    ${({theme}) => theme.media.desktop} {
      text-align: left;
    }
  `}
  ${({descrip}) => descrip && css`
    font-size: ${({theme}) => theme.font.size.xs};

  `}
  .capacity {
    font-size: 19px;
    padding-left: 2px;
  }
}

${({theme}) => theme.media.desktop} {
  font-family: ${({theme}) => theme.font.family.Montserrat};
  font-size: ${({theme}) => theme.font.size.s};
  margin-bottom: 20px;
  line-height: 1.4;
  ${({descrip}) => descrip && css`
    font-size: ${({theme}) => theme.font.size.xs};


  `}
}
`

const StyledHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.media.desktop} {
    min-width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }


`
const Button = styled.button`
  width: auto;
  min-width: 250px;
  height: 40px;
  background-color: transparent;
  border: 2px ${({theme}) => theme.primary} solid;
  border-radius: 5px;
  border-bottom: 3px ${({theme}) => theme.primary} solid;
  border-right: 3px ${({theme}) => theme.primary} solid;
  font-family: ${({theme}) => theme.font.family.Montserrat};
  font-size: ${({theme}) => theme.font.size.xs};
  font-weight: 600;
  transition: all 0.2s 0.3s ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: scale(1.07);
    text-decoration: none;
    background-color: ${({theme}) => theme.primary};
    border: 2px solid black;
  }

  &:active {
    transform: scale(0.94);
  }`

const Form = styled.form`
  display: flex;
  justify-content: center;

  ${({theme}) => theme.media.desktop} {
    justify-content: flex-start;
  }
`


const StyledTitle = styled.div`
  width: auto;
  height: 45px;
  display: grid;
  place-items: center;
  margin-top: 10px;

  h2 {
    margin: 0;
  }

  ${({theme}) => theme.media.desktop} {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    place-items: center start;
  }
`
const StyledDes = styled(StyledP)`
  border-top: 1px rgba(0, 0, 0, 0.3) solid;
  padding-top: 20px;
  margin: 40px 10% 40px 9%;
  grid-column: span 2;
  font-size: 16px;
  color: black;

  ${({theme}) => theme.media.desktop} {
    padding: 15px 0;
    width: 80%;
    line-height: 1.5;
    font-size: 18px;
  }
`
const StyledSelect = styled.select`
  width: auto;
  min-width: 200px;
  height: auto;
  min-height: 40px;
  background-color: transparent;
  border: 2px ${({theme}) => theme.primary} solid;
  border-radius: 5px;
  border-bottom: 3px ${({theme}) => theme.primary} solid;
  border-right: 3px ${({theme}) => theme.primary} solid;
  font-family: ${({theme}) => theme.font.family.Montserrat};
  font-size: ${({theme}) => theme.font.size.xs};
  font-weight: 600;
  padding: 0 10px;
  margin: 0 0 10px 10px;

  option {
    padding: 15px;
    height: 30px;
  }
`
const StyledCapacity = styled.div`
  display: grid;
  place-items: center;

  ${({theme}) => theme.media.desktop} {
    place-items: start;

  }
`

export default function ProjectsDetails({data}) {
    const flavoreds = data.flavored.nodes;
    const honeys = data.honeys.nodes;
    const [value, setValue] = useState(1)
    const [id, setId]=useState(flavoreds[0].id);
    const {addToOrder} = useHoney({flavoreds});
    const {bought} = useContext(orderContext);
    const [valueCap, setValueCap] = useState('');

  const honey = honeys.find((honey) => honey.id === id );
    useEffect(() => {

        if (document.getElementById('capacity') !== null && valueCap === '') {
            setValueCap(document.getElementById('capacity').value)
        } else {
            setValueCap(document.getElementById('capacityOne').innerHTML)
        }
    }, []);

    const updateValue = (e) => {
        setValueCap(e.target.value)
    };

    const handleAddValue = () => {
        setValue(value + 1)
    }
    const handleSubValue = () => {
        setValue(value - 1)
    }


  const quality = flavoreds[0].capacity.find((capacity) => capacity.choicecapacity ===valueCap);
  useEffect(() => {
    if(flavoreds[0].capacity.length > 1 && quality !== undefined) {
      setId(quality.link.id);
    }else{
      setId(flavoreds[0].id)
    }

  }, [valueCap, addToOrder]);


    return (
        <>
            {bought.modal === true ? (<Modal modalType modalContext={"Kupiłeś produkt"} flavored={honey}
                                            isModalOpen={bought.modal}/>) : null}
            <StyledWrapper>
                <Product>
                    {flavoreds.map((flav) => (
                        <StyledHead key={flav.id}>
                             <StyledImage src={honey.fotoOffer.url}/>
                            <StyledRightSite>
                                <StyledTitle>
                                    <h2>{honey.title} - {valueCap}</h2>
                                </StyledTitle>
                                <StyledCapacity>
                                            <StyledP price><span>Cena: </span><strong>{formatMoney(honey.price)}</strong></StyledP>

                                    {flav.capacity.length > 1 ? (<StyledP descrip>Dostępne pojemności</StyledP>) : (
                                        <StyledP descrip>Dostępna pojemności: <strong><span id='capacityOne' className='capacity'>{flav.capacity[0].choicecapacity}</span></strong></StyledP>)}

                                    {flav.capacity.length > 1 ?
                                        <StyledSelect name='capacity' id='capacity' onChange={updateValue}>
                                            {flav.capacity.map((item) => <option key={item.id}
                                                                                 value={item.choicecapacity}>
                                              {item.choicecapacity}

                                            </option>)}

                                        </StyledSelect> : null}


                                </StyledCapacity>
                                <Form>
                                    <StyledP>ilość:</StyledP>
                                    <InputNumber>
                                        <button disabled={value < 2} type="button"
                                                onClick={handleSubValue}>&minus;</button>
                                        <span>{value}</span>
                                        <button type="button" onClick={handleAddValue}>&#43;</button>
                                    </InputNumber>

                                </Form>
                              {id === null ? (<Button type="button"
                                                         onClick={() => addToOrder({
                                                           id: flav.id,
                                                           quality: value,

                                                         })}>dodaj do koszyka
                              </Button>) : (<Button type="button"
                                                    onClick={() => addToOrder({
                                                      id: id,
                                                      quality: value,

                                                    })}>dodaj do koszyka
                              </Button>)}
                            </StyledRightSite>
                            <StyledDes dangerouslySetInnerHTML={{__html: flav.description}}/>
                        </StyledHead>
                    ))}

                </Product>

            </StyledWrapper>
        </>)
}

export const query = graphql`
    query ($slug: String!) {
        flavored : allDatoCmsOffer(filter:{slug:{ eq:$slug}}) {
            nodes {
                capacity {
                    choicecapacity
                    link {
                        title
                        slug
                        id
                    }
                }
                description
                id
                slug
                fotoOffer {
                    url
                }
                title
                typeOfMany
                price
                imgSecend {
                    url
                }
                priceSecend
            }
        }
        honeys : allDatoCmsOffer {
            nodes {
                capacity {
                    choicecapacity                    
                }               
                id
                slug
                fotoOffer {
                    url
                }
                title               
                price
              
            }
        }
    }
    
    
`;